import { TextField } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { NumericFormat } from 'react-number-format'
import { parseCurrency } from '../utils/utils'

interface CurrencyInputProps {
  label: string
  value: number
  labelHidden?: boolean
  className?: string
  variation?: 'quiet'
  decimalDigits?: number
  fixedDecimalScale?: boolean
  onValueChange: (value: number) => void
}

const CurrencyInput: FunctionComponent<CurrencyInputProps> = ({
  label,
  value,
  labelHidden = false,
  onValueChange,
  className,
  variation,
  decimalDigits = 2,
  fixedDecimalScale = true,
}) => {
  return (
    <NumericFormat
      className={className}
      label={label}
      labelHidden={labelHidden}
      type='text'
      inputMode='numeric'
      pattern='\d*'
      variation={variation}
      size='small'
      minWidth='8rem'
      prefix={'$'}
      decimalScale={decimalDigits}
      allowNegative={false}
      fixedDecimalScale={fixedDecimalScale}
      thousandSeparator=','
      customInput={TextField}
      value={value}
      valueIsNumericString={true}
      onValueChange={(v) => {
        let value = parseCurrency(v.value)
        if (isNaN(value)) value = 0
        onValueChange(Number(value))
      }}
    />
  )
}

export default CurrencyInput
