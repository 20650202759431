import { Flex, Heading, Loader, Text } from '@aws-amplify/ui-react'
import { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CompanyHero from '../components/CompanyHero'
import CompanyOverview from '../components/CompanyOverview'
import FeatureRequest from '../components/FeatureRequest'
import ProductTable from '../components/ProductTable'
import ProfileSearch from '../components/ProfileSearch'
import { SelectOpportunityText } from '../components/SelectOpportunityText'
import MissingDataCard from '../components/missingDataCard'
import { useAmplifyUser } from '../contexts/amplifyUserContext'
import { useOpportunity, useSetOpportunity } from '../contexts/opportunityContext'
import DataSourcesView, { DocTags } from '../figma-components/DataSourcesView'
import ExtendProfileView from '../figma-components/ExtendedProfileView'
import ProfileNavBar from '../figma-components/ProfileNavBar'
import { useCompanyInfoV2 } from '../hooks/useCompanyInfoV2'
import { Opportunity } from '../models'
import { restAPIRequest } from '../services/restApiRequest'
import { sendSlack } from '../services/slack'

interface CompanyProfilePageV2Props {
  mode: 'private' | 'public'
}

type Page = {
  name: string
  displayName: string
}

const OVERVIEW = 'OVERVIEW'
const PRODUCTS = 'PRODUCTS'
const CRM = 'CRM'

const pages: Page[] = [
  { name: OVERVIEW, displayName: 'Overview' },
  { name: PRODUCTS, displayName: 'Product Details' },
  { name: CRM, displayName: 'CRM' },
]

const CompanyProfilePageV2: FunctionComponent<CompanyProfilePageV2Props> = ({ mode }) => {
  const isPublic = mode === 'public'
  const params = useParams()
  const [currentPage, setCurrentPage] = useState<Page>(pages[0])
  const opportunity = useOpportunity()
  const setOpp = useSetOpportunity()
  const amplifyUser = useAmplifyUser()
  const orgName = amplifyUser?.org
  const isDemo = orgName?.toLowerCase() === 'demo'
  const name = isPublic ? params?.targetCompany : opportunity?.name
  const dealId = isPublic ? undefined : opportunity?.id
  const [companyInfo, loading, error] = useCompanyInfoV2(name)
  const userEmail = amplifyUser?.email || ''

  const oneOfUs =
    ['demo', 'admin', 'kelley_yu', 'teamone', 'teamtwo', 'test_new_group'].includes(orgName?.toLowerCase() || '') ||
    userEmail?.includes('anand') ||
    userEmail?.includes('rachit') ||
    userEmail?.includes('neil')

  useEffect(() => {
    if (!name)
      return console.error('No name to updateAi', {
        paramsTarget: params.targetCompany,
        paramsId: params.opportunityId,
        oppName: opportunity?.name,
        oppId: opportunity?.id,
        isPublic,
      })
    restAPIRequest({
      path: `companyProfile/${name}/updateAi`,
      method: 'post',
    }).catch((e) => {
      console.error('error running updateAi', e)
      // TODO: show error message saying we cant find this company in crunchbase
    })
  }, [name])

  useEffect(() => {
    if (!companyInfo?.cb_organizationPermalink) return
    if (!companyInfo?.companyName) return
    restAPIRequest({
      path: `companyProfile/${companyInfo.companyName}/updateCrunchbase`,
      body: {
        cb_organizationPermalink: companyInfo?.cb_organizationPermalink,
      },
      method: 'post',
    }).catch((e) => {
      console.error('error running update', e)
    })
  }, [companyInfo?.cb_organizationPermalink, companyInfo?.companyName])

  useEffect(() => {
    if (loading) return
    if (!companyInfo?.companyLabel) {
      if (!oneOfUs) {
        sendSlack(`:alert: ERROR! No info (${userEmail}) searched for ${name}: ${new URL(`https://app.playbookmna.com/profiles/${name}`)}`)
      }
      return
    }
    if (!isDemo) {
      if (!oneOfUs) {
        sendSlack(
          `:eye: (${userEmail}) is looking at ${companyInfo?.companyLabel}: ${new URL(`https://app.playbookmna.com/review/${companyInfo?.companyName}`)} It is showing ${
            companyInfo.products?.length
          } products`
        )
      }
    }
  }, [userEmail, companyInfo?.companyLabel, companyInfo?.companyName, isDemo, loading, name, oneOfUs, companyInfo?.products?.length])

  useEffect(() => {
    if ((!opportunity?.id && params.opportunityId) || (params.opportunityId && opportunity && params.opportunityId !== opportunity?.id)) {
      setOpp(params.opportunityId)
    }
  }, [opportunity?.id, params.opportunityId, setOpp])

  if (loading && !error) return <Loader />
  if (error) return <Text>Error loading company information. Please refresh the page.</Text>

  if (!opportunity && params.opportunityId) {
    return <Loader />
  }

  if (!isPublic && !opportunity) return <SelectOpportunityText />

  let pageComponent = <Loader />

  const deal = isPublic ? ({} as Opportunity) : opportunity

  if (!companyInfo) {
    return (
      <MissingDataCard>
        <Flex direction='column'>
          <Heading level={5}>Working on it</Heading>
          <Text>
            Sit back while we collect information for <strong>{name}</strong>, it will be ready in less than 2 hours.
          </Text>
          <Text>This page will automatically refresh once completed and we will notify you via email.</Text>
          <Text>Look at other companies while you wait!</Text>
          <ProfileSearch enableNavigate={true} />
        </Flex>
      </MissingDataCard>
    )
  }

  const companyDescription = companyInfo?.cb_description || companyInfo?.cb_fullDescription || 'No description available'
  let websiteSources = [companyInfo.cb_website || '']
  let docTag: DocTags | undefined = undefined
  switch (currentPage.name) {
    case OVERVIEW:
      // docTag = 'overview'
      pageComponent = <CompanyOverview companyInfo={companyInfo} opportunity={deal} />
      break
    case PRODUCTS:
      docTag = 'products'
      pageComponent = <ProductTable products={deal?.overrides?.products || companyInfo.products || []} />
      break
    case CRM:
      // docTag = 'crm'
      pageComponent = <ExtendProfileView dealId={dealId} companyName={companyInfo.companyName} companyLabel={companyInfo.companyLabel} />
      break
    default:
      break
  }

  if (currentPage.name !== OVERVIEW && !isDemo && isPublic && (!companyInfo.products || companyInfo.products?.length === 0) && currentPage.name !== CRM) {
    pageComponent = (
      <FeatureRequest
        featureName={'companyProfile'}
        featureArg={companyInfo.companyName}
        info={{ companyName: companyInfo.companyName }}
        requestMsg='Request information on company.'
        requestedMsg='Sit back while we collect information. it will be ready in less than 2 hours. This page will automatically refresh once completed and we will notify you via email.'
      />
    )
  }

  if (isPublic) docTag = undefined
  const dataSources = <DataSourcesView websiteSources={websiteSources} opportunity={deal} docTag={docTag} />

  return (
    <Flex direction='column' paddingTop='4px'>
      <ProfileSearch enableNavigate={true} />
      <CompanyHero
        publicComps={companyInfo.ai_public_comps || []}
        iconUrl={companyInfo.iconUrl}
        companyName={companyInfo.companyName}
        companyLabel={companyInfo.companyLabel || companyInfo.companyName}
        description={companyDescription}
        isPublic={isPublic}
        marketComps={companyInfo.marketComps || []}
        websiteSources={websiteSources}
      />
      <Flex direction='column' gap='0'>
        <ProfileNavBar
          setPage={(displayName) => {
            setCurrentPage(pages.find((predicate) => predicate.displayName === displayName) || pages[0])
          }}
          pages={pages.map((p) => p.displayName)}
          currentPage={currentPage.displayName}
        />
        {pageComponent}
      </Flex>
      {dataSources}
    </Flex>
  )
}

export default CompanyProfilePageV2
