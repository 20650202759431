import { useQuery } from '@tanstack/react-query'
import { restAPIRequest } from '../services/restApiRequest'
import { defaultRetry } from '../utils/utils'

export type CompanyTiering = {
  index: number
  'Company Name': string
  PlaybookID: string

  Coverage: string
  Tier: string
  'Point Person': string
  'Next Raise Date': string
  'Next Touchpoint': string
}

type UseInvestmentsProps = {
  companyName: string
  enabled?: boolean
}

export function useTiering({ companyName, enabled = true }: UseInvestmentsProps): [CompanyTiering[], boolean, Error | undefined] {
  const { data, isLoading, error } = useQuery({
    queryKey: ['companyProfile/tiering', companyName],
    enabled: !!companyName && enabled,
    queryFn: queryTiering(companyName),
    retry: defaultRetry,
  })

  return [data?.tiering || [], isLoading, error as Error | undefined]
}

export function queryTiering(companyName: string) {
  return async () =>
    restAPIRequest<{ tiering: CompanyTiering[] }>({
      path: `companyProfile/${companyName}/tiering`,
      method: 'get',
    })
}
