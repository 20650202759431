import { FunctionComponent } from 'react'
import InputTable from '../components/InputTable'
import { DealLogHeaders } from '../hooks/useDealLog'
import GlassCard from './GlassCard'

interface MyDealsProps {
  deals?: (DealLogHeaders & Partial<{ label: string | null; id: string | null; createdAt: string | null; updatedAt: string | null }>)[]
}

const Cols = [
  {
    header: 'Deal Name',
    accessorKey: `label`,
    meta: {
      type: 'link',
      valueToLink: (i: string, row) => `deal/${row.id}/target_profile`,
    },
  },
  {
    header: 'NEP Lead',
    accessorKey: 'nepLead',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Close Date',
    accessorKey: 'closeDate',
    sortingFn: 'datetime',
    aggregatedCell: ({ getValue }: { getValue: <T>() => T }) => {
      const value = getValue() as any[]
      if (!value[0]) return ''
      return new Date(value[0] as any).toLocaleDateString('en-US', { timeZone: 'UTC' }) + ' to ' + new Date(value[1] as any).toLocaleDateString('en-US', { timeZone: 'UTC' })
    },
    meta: {
      type: 'viewOnlyDate',
    },
  },
  {
    header: 'Round Name',
    accessorKey: 'roundName',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'VC Source',
    accessorKey: 'vcSource',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Rami Tiering',
    accessorKey: 'ramiTiering',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Mgmt. meeting',
    accessorKey: 'mgmtMeeting',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Expert Calls',
    accessorKey: 'expertCalls',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Term Sheet Submitted',
    accessorKey: 'termSheetSubmitted',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'IC Discussions',
    accessorKey: 'iCDiscussions',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Deck Created',
    accessorKey: 'deckCreated',
    meta: {
      type: 'viewOnlyString',
    },
  },
]

const MyDealsTable: FunctionComponent<MyDealsProps> = ({ deals }) => {
  if (!deals || !deals.length) return null
  const data = deals.map((i) => ({
    id: i.id,
    label: i['Company'] || i.label || '',
    nepLead: i['NEP Lead'],
    closeDate: (i['Close Date'] && new Date(i['Close Date'])) || '',
    roundName: i['Round Name'],
    vcSource: i['VC Source'],
    ramiTiering: i['Rami Tiering'],
    mgmtMeeting: i['Mgmt. meeting'],
    expertCalls: i['Expert Calls'],
    termSheetSubmitted: i['Term Sheet Submitted'],
    iCDiscussions: i['IC Discussions'],
    deckCreated: i['Deck Created'],
  }))

  return (
    <GlassCard header='My Deals' className='overlayCard'>
      <InputTable
        data={data}
        columns={Cols as any}
        paginate
        enableGrouping={true}
        initState={{
          sorting: [
            {
              id: 'closeDate',
              desc: true,
            },
          ],
        }}
      />
    </GlassCard>
  )
}

export default MyDealsTable
