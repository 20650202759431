import { Card, Loader, Text } from '@aws-amplify/ui-react'
import React from 'react'

type LoadingWaiterProps = {
  isLoading: boolean
  error?: Error
  children: React.ReactNode
  errorText?: string
}

const LoadingWaiter: React.FC<LoadingWaiterProps> = ({ isLoading, error, children, errorText }) => {
  if (isLoading) {
    return <Loader />
  }
  const text = errorText || 'We encountered an error. If this continues us by clicking chat icon in the bottom right or email us at support@playbookmna.com'
  if (error) {
    return (
      <Card>
        <Text>{text}</Text>
      </Card>
    )
  }

  return children
}

export default LoadingWaiter
