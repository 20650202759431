import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Flex, Label, Input, Loader, Text, Link } from '@aws-amplify/ui-react'
import ReactModal from 'react-modal'
import Select from 'react-select'
import CurrencyInput from '../components/CurrencyInput'
import FundingBenchmarkingDashboards from '../components/FundingBenchmarkingDashboards'
import MultipleInput from '../components/MultipleInput'
import NumberInput from '../components/NumberInput'
import PercentInput from '../components/PercentInput'
import DateInput from '../components/DateInput'
import GlassCard from '../figma-components/GlassCard'
import { FilesOpenIcon } from '../ui-components'
import MissingDataCard from '../components/missingDataCard'
import { useAllFinancials } from '../hooks/useAllFinancials'
import '../styles/dashboard.css'
import '../styles/grid.scss'
import '../styles/table.css'
import { currencyFormatterShort, multipleFormatter, numberFormatter, percentFormatter, removeDuplicateValues } from '../utils/utils'

type FilterState = {
  savedFilterName: string | undefined
  selectedNames: {
      value: string
      label: string
  }[]
  highlightName: string | undefined
  dates: {
      min: Date
      max: Date
      originalMin: Date
      originalMax: Date
  }
  min: { [key: string]: number }
  max: { [key: string]: number }
}

const filters = {
  preMoneyValuation: { displayName: 'Pre-Money Valuation', input: CurrencyInput, inputArgs: { decimalDigits: 0 }, formatter: currencyFormatterShort },
  postMoneyValuation: { displayName: 'Post-Money Valuation', input: CurrencyInput, inputArgs: { decimalDigits: 0 }, formatter: currencyFormatterShort },
  roundAmount: { displayName: 'Round Amount', input: CurrencyInput, inputArgs: { decimalDigits: 0 }, formatter: currencyFormatterShort },
  preEvLtmRev: { displayName: 'Pre EV / LTM Rev', input: MultipleInput, formatter: multipleFormatter },
  postEvLtmRev: { displayName: 'Post EV / LTM Rev', input: MultipleInput, formatter: multipleFormatter },
  preEvLtmProfit: { displayName: 'Pre EV / LTM GP', input: MultipleInput, formatter: multipleFormatter },
  postEvLtmProfit: { displayName: 'Post EV / LTM GP', input: MultipleInput, formatter: multipleFormatter },
  preEvNtmRev: { displayName: 'Pre EV / NTM Rev', input: MultipleInput, formatter: multipleFormatter },
  postEvNtmRev: { displayName: 'Post EV / NTM Rev', input: MultipleInput, formatter: multipleFormatter },
  preEvNtmProfit: { displayName: 'Pre EV / NTM GP', input: MultipleInput, formatter: multipleFormatter },
  postEvNtmProfit: { displayName: 'Post EV / NTM GP', input: MultipleInput, formatter: multipleFormatter },
  preEvArr: { displayName: 'Pre EV / ARR', input: MultipleInput, formatter: multipleFormatter },
  postEvArr: { displayName: 'Post EV / ARR', input: MultipleInput, formatter: multipleFormatter },
  preEvNextArr: { displayName: 'Pre EV / ARR FY+1', input: MultipleInput, formatter: multipleFormatter },
  postEvNextArr: { displayName: 'Post EV / ARR FY+1', input: MultipleInput, formatter: multipleFormatter },
  grossRetentionPercent: { displayName: 'Gross Dollar Retention', input: PercentInput, formatter: percentFormatter },
  netRetentionPercent: { displayName: 'Net Dollar Retention', input: PercentInput, formatter: percentFormatter },
  arrGrowthPercent: { displayName: 'ARR Growth % YoY', input: PercentInput, formatter: percentFormatter },
  implied5yrLTVPerCAC: { displayName: 'LTV / CAC', input: MultipleInput, formatter: multipleFormatter },
  burnMultiple: { displayName: 'Burn Multiple', input: MultipleInput, formatter: multipleFormatter },
  netNewARRYoYGrowthPercent: { displayName: 'Net New ARR - YoY Growth', input: PercentInput, formatter: percentFormatter },
  magicNumber: { displayName: 'Magic Number', input: MultipleInput, formatter: multipleFormatter },
  customers: { displayName: 'Total Customers', input: NumberInput, formatter: numberFormatter },
  grossMarginPercent: { displayName: 'Gross Margin %', input: PercentInput, formatter: percentFormatter },
  salesEfficiencyMultiple: { displayName: 'New Sales ARR / S&M Expenses', input: MultipleInput, formatter: multipleFormatter },
  totalRaised: { displayName: 'Total Raised', input: CurrencyInput, inputArgs: { decimalDigits: 0 }, formatter: currencyFormatterShort },
}

const MetricsAndBenchmarkingPage: FunctionComponent = () => {
  const [allFinancialsData, isFinLoading, error] = useAllFinancials()
  const [selectedNames, setSelectedNames] = useState<{ value: string; label: string }[]>([])
  const [highlightName, setHighlightName] = useState<string>()
  const [selectedFilter, setSelectedFilter] = useState<FilterState>()
  const [savedFilterName, setSavedFilterName] = useState<string>()
  const [isSaveFiltersModalOpen, setIsSaveFiltersModalOpen] = useState(false)
  const [isSelectFiltersModalOpen, setIsSelectFiltersModalOpen] = useState(false)
  // preprare metrics and benchmarking dataset
  const [min, setMin] = useState(Object.keys(filters).reduce((p, c) => ({ ...p, [c]: 1000000000 }), {}))
  const [max, setMax] = useState(Object.keys(filters).reduce((p, c) => ({ ...p, [c]: 0 }), {}))

  const [totalMin, setTotalMin] = useState(Object.keys(filters).reduce((p, c) => ({ ...p, [c]: 1000000000 }), {}))
  const [totalMax, setTotalMax] = useState(Object.keys(filters).reduce((p, c) => ({ ...p, [c]: 0 }), {}))

  const [dates, setDates] = useState<{ min: Date; max: Date; originalMin: Date; originalMax: Date }>({
    originalMin: new Date(),
    originalMax: new Date(),
    min: new Date(),
    max: new Date(),
  })
  const names = removeDuplicateValues(allFinancialsData?.map((row) => row.companyLabel || '') || []).map((i) => ({ value: i, label: i }))

  useEffect(() => {
    if (!allFinancialsData) return
    const minDate = new Date('9999-12-31')
    const maxDate = new Date(0)

    allFinancialsData.forEach((row) => {
      const date = new Date(row.date)
      if (date < minDate) minDate.setTime(date.getTime())
      if (date > maxDate) maxDate.setTime(date.getTime())
    })
    const newMin = Object.keys(filters).reduce((p, c) => {
      const min = Math.min(...allFinancialsData.map((row) => row[c]))
      return { ...p, [c]: min }
    }, {})
    const newMax = Object.keys(filters).reduce((p, c) => {
      const max = Math.max(...allFinancialsData.map((row) => row[c]))
      return { ...p, [c]: max }
    }, {})
    setMin(newMin)
    setMax(newMax)
    setTotalMin(newMin)
    setTotalMax(newMax)
    setDates({ min: minDate, max: maxDate, originalMin: minDate, originalMax: maxDate })
  }, [allFinancialsData])

  const saveFilters = () => {
    // save filters to local storage
    const savedFilters: FilterState[] = JSON.parse(localStorage.getItem('savedFilters') || '[]')
    const filterState: FilterState = { savedFilterName, selectedNames, highlightName, dates, min, max }
    savedFilters.push(filterState)
    localStorage.setItem('savedFilters', JSON.stringify(savedFilters))
    // close and clear modal
    setIsSaveFiltersModalOpen(false)
    setSavedFilterName('')
  }

  const loadFilters = (filter: FilterState | undefined) => {
    if (!filter) return
    // load filters from local storage
    setSelectedNames(filter.selectedNames)
    setHighlightName(filter.highlightName)
    setDates({ // dates retrieved from local storage are strings, converting them to Date objects
      min: new Date(filter.dates.min),
      max: new Date(filter.dates.max),
      originalMin: new Date(filter.dates.originalMin),
      originalMax: new Date(filter.dates.originalMax),
    })
    setMin(filter.min)
    setMax(filter.max)
    // close and clear modal
    setIsSelectFiltersModalOpen(false)
    setSelectedFilter(undefined)
  }

  const deleteFilters = (filter: FilterState | undefined) => {
    if (!filter) return
    // delete filters from local storage
    const savedFilters: FilterState[] = JSON.parse(localStorage.getItem('savedFilters') || '[]')
    // TODO: all filters should have unique names, otherwise all filters will be deleted with the same name
    const newSavedFilters = savedFilters.filter((savedFilter) => savedFilter.savedFilterName !== filter.savedFilterName)
    localStorage.setItem('savedFilters', JSON.stringify(newSavedFilters))
    // close and clear modal
    setIsSelectFiltersModalOpen(false)
    setSelectedFilter(undefined)
  }

  const clearFilters = () => {
    setSelectedNames([])
    setHighlightName(undefined)
    setDates({ ...dates })
    setMin({ ...totalMin })
    setMax({ ...totalMax })
  }

  const handleCloseModal = () => {
    setIsSaveFiltersModalOpen(false)
    setSavedFilterName('')
    setIsSelectFiltersModalOpen(false)
    setSelectedFilter(undefined)
  }

  const metricBenchmarkData = useMemo(() => {
    return (
      allFinancialsData?.filter((row) => {
        if (selectedNames.length && !selectedNames.some((p) => p.label === row.companyLabel)) return false
        if (dates.min !== dates.originalMin && new Date(row.date) < dates.min) return false
        if (dates.max !== dates.originalMax && new Date(row.date) > dates.max) return false
        return Object.keys(filters).every((key) => row[key] >= min[key] && row[key] <= max[key])
      }) || []
    )
  }, [allFinancialsData, dates.max, dates.min, dates.originalMax, dates.originalMin, max, min, selectedNames])

  const renderFilterRow = (key: string) => {
    const filter = filters[key]
    const FilterInput = filter.input
    return (
      <Flex key={key} className='filterRow'>
        <Flex width='8rem'>
          <Label>{filter.displayName}</Label>
        </Flex>
        <Flex direction='column' gap='0.25rem'>
          <FilterInput {...filter.inputArgs} label='Min' labelHidden value={min[key]} onValueChange={(value) => setMin({ ...min, [key]: value })} />
          {/* <small>Min: {filter.formatter(totalMin[key])}</small> */}
        </Flex>
        <span>to</span>
        <Flex direction='column' gap='0.25rem'>
          <FilterInput {...filter.inputArgs} label='Max' labelHidden value={max[key]} onValueChange={(value) => setMax({ ...max, [key]: value })} />
          {/* <small>Max: {filter.formatter(totalMax[key])}</small> */}
        </Flex>
      </Flex>
    )
  }

  const targetData = useMemo(() => (highlightName ? metricBenchmarkData.filter((p) => p.companyLabel === highlightName) : []), [highlightName, metricBenchmarkData])
  const data = metricBenchmarkData
  // use this if we want to remove dup marks useMemo(() => (highlightName ? metricBenchmarkData.filter((p) => p.companyLabel !== highlightName) : metricBenchmarkData), [highlightName, metricBenchmarkData])

  if (isFinLoading) return <Loader />
  if (error)
    return (
      <MissingDataCard>
        <Text>We are unable to show this data right now. Please try again later or contact support@playbookmna.com</Text>
      </MissingDataCard>
    )

  return (
    <Flex direction='column'>
      <GlassCard header='Metrics & Benchmarking' level={0} className='white overflow overlayCard'>
        <Flex marginTop='1rem' gap='0'>
          <Flex direction='column' className='filtersContainer'>
            <Flex justifyContent='space-between'>
              <Text fontSize='1.25rem' fontWeight='600'>
                Filters
              </Text>
              <Flex>
                <Text alignSelf='center' className='clearLink' onClick={() => clearFilters()}>Clear</Text>
                <Link alignSelf='center' onClick={() => setIsSaveFiltersModalOpen(true)}>Save</Link>
                {
                  localStorage.getItem('savedFilters') &&
                  <Link
                    alignSelf='center'
                    className='loadLink'
                    onClick={() => setIsSelectFiltersModalOpen(true)}
                  >
                    <FilesOpenIcon />
                  </Link>
                }
              </Flex>
            </Flex>
            <Flex key='date' className='filterRow'>
              <Flex width='8rem'>
                <Label>Investment Date</Label>
              </Flex>
              <Flex direction='column' gap='0.25rem'>
                <DateInput label='Min' labelHidden value={dates.min} onChange={(value) => setDates({ ...dates, min: value })} />
                {/* <small>Min: {dates.originalMin.toDateString()}</small> */}
              </Flex>
              <span>to</span>
              <Flex direction='column' gap='0.25rem'>
                <DateInput label='Max' labelHidden value={dates.max} onChange={(value) => setDates({ ...dates, max: value })} />
                {/* <small>Max: {dates.originalMax.toDateString()}</small> */}
              </Flex>
            </Flex>
            {Object.keys(filters).map((key) => renderFilterRow(key))}
          </Flex>
          <Flex direction='column' className='benchmarksContainer'>
            <Flex className='filterTopRow' gap='2rem'>
              <Flex key='company' className='filterRow' gap='1rem'>
                <Flex>
                  <Label>Company</Label>
                </Flex>
                <Flex>
                  <Select
                    isMulti={true}
                    isClearable={true}
                    isLoading={isFinLoading}
                    placeholder={'Select company...'}
                    openMenuOnClick={true}
                    styles={{ control: (s) => ({ ...s, width: '30rem' }), menu: (s) => ({ ...s, width: '30rem' }) }}
                    options={names}
                    value={selectedNames}
                    onChange={(newValue) => {
                      setSelectedNames([...newValue])
                    }}
                  />
                </Flex>
              </Flex>
              <Flex key='highlightCompany' className='filterRow' gap='1rem'>
                <Flex>
                  <Label>Highlight Company</Label>
                </Flex>
                <Flex>
                  <Select
                    isClearable={true}
                    isLoading={isFinLoading}
                    placeholder={'Select company...'}
                    openMenuOnClick={true}
                    styles={{ control: (s) => ({ ...s, width: '17rem' }), menu: (s) => ({ ...s, width: '17rem' }) }}
                    options={selectedNames.length ? selectedNames : names}
                    value={{ value: highlightName, label: highlightName }}
                    onChange={(newValue) => {
                      setHighlightName(newValue?.label)
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
            <FundingBenchmarkingDashboards data={data} targetData={targetData} />
          </Flex>
        </Flex>
      </GlassCard>
      <ReactModal appElement={document.getElementById('app')} isOpen={isSaveFiltersModalOpen} onRequestClose={() => handleCloseModal()}>
        <GlassCard header='Save Filter' className='filterCard'>
          <Flex className='filterCardInput'>
            <Input size='small' placeholder='Enter name...' autoFocus={true} value={savedFilterName} onChange={(e) => setSavedFilterName(e.target.value)}></Input>
          </Flex>
          <Flex className='filterCardControls'>
            <Text alignSelf='center' className='clearLink' onClick={() => handleCloseModal()}>Close</Text>
            <Link alignSelf='center' onClick={() => saveFilters()}>Save</Link>
          </Flex>
        </GlassCard>
      </ReactModal>
      <ReactModal appElement={document.getElementById('app')} isOpen={isSelectFiltersModalOpen} onRequestClose={() => setIsSelectFiltersModalOpen(false)}>
        <GlassCard header='Load Filter' className='filterCard'>
          <Flex className='filterCardInput'>
            <Select
              isClearable={true}
              placeholder={'Select filter...'}
              openMenuOnClick={true}
              styles={{ control: (s) => ({ ...s, width: '17rem' }), menu: (s) => ({ ...s, width: '17rem' }) }}
              options={
                localStorage.getItem('savedFilters') ?
                  JSON.parse(localStorage.getItem('savedFilters') || '[]')
                  .map((filter: FilterState) => ({ value: filter, label: filter.savedFilterName })) :
                  []
                }
              value={{ value: selectedFilter, label: selectedFilter?.savedFilterName }}
              onChange={(filter) => {
                setSelectedFilter(filter?.value)
              }}
            />
          </Flex>
          <Flex className='filterCardControls'>
            <Text alignSelf='center' className='clearLink' onClick={() => handleCloseModal()}>Close</Text>
            <Flex>
              {
                selectedFilter?.savedFilterName &&
                <Text alignSelf='center' className='deleteLink' onClick={() => deleteFilters(selectedFilter)}>Delete</Text>
              }
              <Link alignSelf='center' onClick={() => loadFilters(selectedFilter)}>Load</Link>
            </Flex>
          </Flex>
        </GlassCard>
      </ReactModal>
    </Flex>
  )
}
export default MetricsAndBenchmarkingPage
