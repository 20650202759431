import { Button, Flex, Table, TableBody, TableCell, TableRow, TextAreaField } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import GlassCard from '../figma-components/GlassCard'
import { CompanyTiering } from '../hooks/useTiering'
import { restAPIRequest } from '../services/restApiRequest'

type TieringProps = {
  tiering: CompanyTiering
  companyName: string
  companyLabel: string
}

const TieringView: FunctionComponent<TieringProps> = ({ tiering, companyName, companyLabel }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [updates, setUpdate] = useState<Partial<CompanyTiering>>(tiering ? {} : { index: -1, 'Company Name': companyLabel, PlaybookID: companyName })
  const [isSaving, setSaving] = useState<boolean>(false)
  const hasChanges = Object.keys(updates || {}).length > 0
  async function save() {
    if (isSaving) return
    setSaving(true)

    try {
      const res = await restAPIRequest<{ index: number }>({
        path: `companyProfile/${companyName}/tiering`,
        method: 'post',
        body: { ...updates, index: updates.index || tiering.index },
      })
      setUpdate({ ...updates, index: res.index })
      setIsEditing(false)
    } catch (error) {
      console.error('Failed to save notes', error)
      alert('Failed to save notes, please try again in a moment')
    } finally {
      setSaving(false)
    }
  }

  const editButton = () => {
    return (
      <Button
        size='small'
        isLoading={isSaving}
        onClick={async (e) => {
          e.preventDefault()
          e.stopPropagation()
          try {
            if (isEditing && hasChanges) await save()
            setIsEditing(true)
          } catch (e) {
            console.error(e)
            alert('Failed to save, please try again in a moment')
          }
        }}
      >
        {isEditing ? (hasChanges ? 'Save' : 'Back') : 'Edit'}
      </Button>
    )
  }
  const updateValue = (key) => (v) => {
    setUpdate({
      ...updates,
      [key]: v.target.value,
    })
  }

  const item = { ...tiering, ...updates }
  const keys: (keyof CompanyTiering)[] = ['Coverage', 'Tier', 'Point Person', 'Next Raise Date', 'Next Touchpoint']
  return (
    <GlassCard header={`Tiering Info`} className='overlayCard' expandable={true} initIsExpanded headerButtons={editButton()}>
      <Table caption='' highlightOnHover={true} variation='bordered' size='small'>
        <TableBody>
          {keys.map((key, j) => {
            let body = <ReactMarkdown>{`${item[key] || ''}`}</ReactMarkdown>
            if (isEditing) {
              body = (
                <Flex>
                  <TextAreaField flex={1} label='' labelHidden size='small' rows={1} value={item[key]} onChange={updateValue(key)} />
                </Flex>
              )
            }
            return (
              <TableRow key={j}>
                <TableCell width={'12rem'} fontWeight={600}>
                  {key}
                </TableCell>
                <TableCell>{body}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </GlassCard>
  )
}

export default TieringView
