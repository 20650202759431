import { restAPIRequest } from './restApiRequest'

type DocLoadRes = {
  documentID: string
}
export async function loadDoc(file: Blob | undefined, opportunityId: string, s3Key: string): Promise<{ documentId: string; documentName: string; documentType: string }> {
  if (!file) {
    throw new Error('missing file')
  }

  const data = new FormData()

  const fileType = file.type || 'text'
  data.append('file', file)
  // @ts-ignore blob does not contain name
  data.append('documentName', file.name || 'file')
  data.append('documentType', fileType)
  data.append('s3Key', s3Key)
  const { documentID } = await restAPIRequest<DocLoadRes>({
    body: data,
    method: 'post',
    path: `deals/${opportunityId}/docs/upload`,
  })

  // @ts-ignore blob does not contain name
  return { documentId: documentID, documentName: file.name, documentType: fileType }
}
