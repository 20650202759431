import { DataStore } from 'aws-amplify/datastore'
import { list, remove, uploadData } from 'aws-amplify/storage'

import { Opportunity } from '../models'

export const OpportunityBasePath = 'user-files/opportunities'

type FileUploadOpts = {
  contentType?: string
  path?: string
}

export async function uploadFileForOpportunity(op: Opportunity, file: File, opts: FileUploadOpts = {}) {
  const prefix = opts.path ? `${opts.path}/` : ''
  try {
    const { path } = await uploadData({
      path: getPathForOpportunity(op, `${prefix}${file.name}`),
      data: file,
      options: {
        contentType: opts.contentType || file.type,
      },
    }).result
    return path
  } catch (error) {
    console.error('Error uploading file: ', error)
    throw error
  }
}

export async function listFilesForOpportunity(op: Opportunity, path?: string) {
  const prefix = path ? `${path}/` : ''
  const out = await list({
    path: getPathForOpportunity(op, prefix),
    options: { pageSize: 1000 },
  })
  return out.items
}

export function getPathForOpportunity(opportunity: Opportunity, path: string) {
  return (info) => `private/${info.identityId}/${OpportunityBasePath}/${opportunity.id}/${path}`
}

export async function deleteFile(path: string) {
  return await remove({ path })
}

export async function deleteFileFromOpportunity(opportunity: Opportunity, path: string) {
  try {
    await deleteFile(path)
    await DataStore.save(
      Opportunity.copyOf(opportunity, (updated) => {
        Object.assign(updated, {
          files: updated.files?.filter((u) => {
            return u.s3Key !== path
          }),
        })
      })
    )
  } catch (error) {
    console.error('Error deleting file: ', error)
  }
}
