import { Flex, Heading, Loader, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { useTiering } from '../hooks/useTiering'
import '../styles/companyOverview.css'
import GlassCard from './GlassCard'

interface TieringProps {
  companyName: string
}

const TieringViewOnly: FunctionComponent<TieringProps> = ({ companyName }) => {
  const [tiering, isLoading, error] = useTiering({ companyName })
  if (isLoading) return <Loader />
  if (error || !tiering || !tiering.length) return null
  return (
    <GlassCard header='Tiering' className='overlayCard relationshipsCard' style={{ padding: '1rem' }}>
      <Flex direction='column' maxHeight='20rem' className='relationshipsCardContent'>
        {tiering.map((item, i) => {
          return (
            <GlassCard key={i} header='' level={2} className='marketSummaryCard overlayCard'>
              <Flex direction='column' gap='0.25rem'>
                <Heading className='marketSummaryHeading'>Coverage</Heading>
                <Text className='marketSummaryMetric'>{item.Coverage || '-'}</Text>
                <Heading className='marketSummaryHeading' marginTop='0.5rem'>
                  Point Person
                </Heading>
                <Text className='marketSummaryMetric'>{item['Point Person'] || '-'}</Text>
                <Heading className='marketSummaryHeading' marginTop='0.5rem'>
                  Tier
                </Heading>
                <Text className='marketSummaryMetric'>{item.Tier || '-'}</Text>
                <Heading className='marketSummaryHeading' marginTop='0.5rem'>
                  Next Raise Date
                </Heading>
                <Text className='marketSummaryMetric'>{item['Next Raise Date'] || '-'}</Text>
                <Heading className='marketSummaryHeading' marginTop='0.5rem'>
                  Next Touchpoint
                </Heading>
                <Text className='marketSummaryMetric'>{item['Next Touchpoint'] || '-'}</Text>
              </Flex>
            </GlassCard>
          )
        })}
      </Flex>
    </GlassCard>
  )
}

export default TieringViewOnly
