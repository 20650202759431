import { DataStore } from '@aws-amplify/datastore'
import { Loader } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import { CompanyInfoV3 } from '../API'
import CompanyOverviewFigma from '../figma-components/CompanyOverview'
import { useConfigTable } from '../hooks/useConfigTable'
import { useFinancials } from '../hooks/useFinancials'
import { Opportunity } from '../models'
import { restAPIRequest } from '../services/restApiRequest'
import { getMonthsBetweenDates, removeDuplicateValues, taxonomiesFromInfo } from '../utils/utils'

interface CompanyOverviewProps {
  opportunity?: Opportunity
  companyInfo: CompanyInfoV3
}
// TODO: delete all these 0 data
function zeroCheck(s: string | undefined | null) {
  if (!s) return 'N/A'
  if (s === '0') return 'N/A'
  return s
}

const CompanyOverview: FunctionComponent<CompanyOverviewProps> = ({ companyInfo, opportunity = {} as Opportunity }) => {
  const [capTableV1, setCapTableV1] = useState(() => JSON.parse(opportunity?.capTableV1 || '[]'))

  const companyName = companyInfo?.companyName
  const companyLabel = companyInfo?.companyLabel
  const cb_foundedDate = companyInfo?.cb_foundedDate
  const companyType = companyInfo?.companyType
  const cb_acquisitionStatus = companyInfo?.cb_acquisitionStatus
  const cb_website = companyInfo?.cb_website
  const linkedInWebsite = companyInfo?.linkedinUrl
  const headquartersLocation = companyInfo?.headquartersLocation
  const headquartersRegion = companyInfo?.headquartersRegion
  const fullTimeEmployeeCount = companyInfo?.fullTimeEmployeeCount
  const fullTimeEmployeeCountGrowth6m = companyInfo?.fullTimeEmployeeCountGrowth6m
  const fullTimeEmployeeCountGrowth1y = companyInfo?.fullTimeEmployeeCountGrowth1y
  const fullTimeEmployeeCountGrowth2y = companyInfo?.fullTimeEmployeeCountGrowth2y
  const targetFinance = opportunity?.financialDataV2?.find((f) => f.type === 'ltm') || {}
  const cyberdb_rev = typeof companyInfo?.cyberdb_revenues === 'string' ? parseFloat(companyInfo.cyberdb_revenues) : companyInfo?.cyberdb_revenues
  const ltmRevenue = targetFinance?.totalRevenue || companyInfo.publicFinancialData?.ltmRevenue || cyberdb_rev

  const cbOrg = companyInfo?.cb_organizationPermalink || ''
  const queryCbOrganizationName = {
    findKey: 'organization_name_url',
    value: cbOrg,
  }
  const [financials] = useFinancials(opportunity.id)
  const [capitalRaiseTable, capitalRaiseLoaders] = useConfigTable('cyber_transaction_capitalraise', queryCbOrganizationName, !!cbOrg)
  const raiseNames = capitalRaiseTable.map((t) => t.playbook_transaction_name).join(',')
  const queryByMultiPlaybookTransactionName = {
    findKey: 'playbook_transaction_name',
    value: raiseNames.split(','),
  }

  const [aiTable, aiLoaders] = useConfigTable('ai_transaction_info', queryByMultiPlaybookTransactionName, !!raiseNames)

  if (cbOrg && capitalRaiseLoaders.get) return <Loader />
  if (raiseNames && aiLoaders.get) return <Loader />

  const taxonomy = taxonomiesFromInfo(companyInfo)
  if (!taxonomy.length) taxonomy.push('N/A')

  const totalEquityRaised = opportunity?.totalEquityRaised || companyInfo?.cb_totalFundingAmount || 0
  const acquiredBy = zeroCheck(companyInfo?.cb_acquiredByLabel)
  const acquisitionStatus = zeroCheck(companyInfo?.cb_acquisitionStatus)

  capitalRaiseTable.sort((a, b) => new Date(a.announced_date).getTime() - new Date(b.announced_date).getTime())

  const raiseData = capitalRaiseTable?.map((t) => ({
    moneyRaised: t.money_raised_currency_in_usd,
    announcedDate: new Date(t.announced_date).toISOString(),
    announcedYear: new Date(t.announced_date).getFullYear(),
    leadInvestors: t.lead_investors,
    fundingType: t.funding_type,
    preMoneyValuation: t.pre_money_valuation_currency_in_usd,
    description: aiTable[0]?.description || '',
  }))

  const keyInvestors =
    companyInfo?.cb_top5Investors?.split(',').join(', ') ||
    removeDuplicateValues(
      capitalRaiseTable?.reduce((p, c) => {
        if (!c.investor_names) return p
        return [...p, ...c.investor_names.split(',').map((n) => n.trim())]
      }, [] as string[])
    ).join(', ')
  const lastPreMoneyValuation = opportunity?.lastPreMoneyValuation || raiseData[raiseData.length - 1]?.moneyRaised || 0
  const monthsSinceLastDeal = getMonthsBetweenDates(capitalRaiseTable[capitalRaiseTable.length - 1]?.announced_date, new Date())

  const saveCapTable = async () => {
    await DataStore.save(
      Opportunity.copyOf(opportunity, (newOpp) => {
        Object.assign(newOpp, {
          capTableV1: JSON.stringify(capTableV1),
        })
      })
    )
    await restAPIRequest({
      path: `deals/${opportunity.id}/financials/calc`,
      method: 'post',
      body: {
        // @ts-ignore
        version: opportunity._version + 1,
      },
    })
  }

  return (
    <CompanyOverviewFigma
      companyName={companyName}
      companyLabel={companyLabel}
      raiseData={raiseData}
      cb_foundedDate={cb_foundedDate}
      // companyType={companyType}
      // cb_acquisitionStatus={cb_acquisitionStatus}
      acquiredBy={acquiredBy}
      cb_website={cb_website}
      linkedInWebsite={linkedInWebsite}
      headquartersLocation={headquartersLocation}
      headquartersRegion={headquartersRegion}
      ltmRevenue={ltmRevenue}
      totalEquityRaised={totalEquityRaised}
      fullTimeEmployeeCount={fullTimeEmployeeCount}
      fullTimeEmployeeCountGrowth6m={fullTimeEmployeeCountGrowth6m}
      fullTimeEmployeeCountGrowth1y={fullTimeEmployeeCountGrowth1y}
      fullTimeEmployeeCountGrowth2y={fullTimeEmployeeCountGrowth2y}
      // lastPreMoneyValuation={lastPreMoneyValuation}
      // lastPostMoneyValuation={''}
      monthsSinceLastDeal={monthsSinceLastDeal}
      acquisitionStatus={acquisitionStatus}
      keyInvestors={keyInvestors}
      financials={financials}
      updateCapTable={setCapTableV1}
      saveCapTable={saveCapTable}
      capTableV1={capTableV1}
    />
  )
}

export default CompanyOverview
