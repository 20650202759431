import { Alert, Button, Flex, Loader, Text } from '@aws-amplify/ui-react'
import { fetchAuthSession } from 'aws-amplify/auth'
import { DataStore } from 'aws-amplify/datastore'
import { FunctionComponent, useCallback, useState } from 'react'
import FileUploader from '../components/FileUploader'
import FinancialInput from '../components/FinancialInput'
import { useOpportunity } from '../contexts/opportunityContext'
import GlassCard from '../figma-components/GlassCard'
import { FinancialDataV2, Opportunity } from '../models'
import { restAPIRequest } from '../services/restApiRequest'

const FinancialPage: FunctionComponent = () => {
  const [error, setError] = useState('')
  const [isManual, setIsManual] = useState(false)
  const opportunity = useOpportunity()
  const [isUploading, setIsUploading] = useState(false)

  const onUpload = useCallback(
    async (s3Key: string, file: File) => {
      if (!opportunity) return console.error('no opportunity')
      setIsUploading(true)
      try {
        const data = new FormData()
        const session = await fetchAuthSession()

        data.append('file', file)
        data.append('filename', file.name || 'data.xlsx')
        data.append('s3Key', s3Key)
        data.append('identityId', session.identityId || '')

        await restAPIRequest({
          path: `deals/${opportunity.id}/financials/upload`,
          method: 'post',
          body: data,
        })
      } catch (error: any) {
        setError(error?.message || error || 'unknown')
        console.error('Error uploading financials', error)
      } finally {
        setIsUploading(false)
      }
    },
    [opportunity]
  )

  // TODO: remove this check
  if (!opportunity) return null

  async function submit(data: FinancialDataV2[]) {
    if (!opportunity) return alert('Select a valid target company')
    try {
      await DataStore.save(
        Opportunity.copyOf(opportunity, (updated) => {
          Object.assign(updated, {
            financialDataV2: data,
          })
        })
      )

      await restAPIRequest({
        path: `deals/${opportunity.id}/financials/calc`,
        method: 'post',
        body: {
          // @ts-ignore
          version: opportunity._version + 1,
        },
      })
    } catch (error: any) {
      console.error('marketMapCompSet: Error saving to datastore', error.message, error)
      alert('Error saving, check inputs and try again')
    }
  }

  if (!isManual && (!opportunity?.financialDataV2 || opportunity.financialDataV2.length === 0)) {
    return (
      <GlassCard header='Financials' level={0}>
        {error && (
          <Alert variation='error'>
            <Text>Issue uploading your data, please try again</Text>
          </Alert>
        )}
        {isUploading ? (
          <Flex direction='column' gap='1rem'>
            <Loader />
            <Text>Please wait while we process your data.</Text>
          </Flex>
        ) : (
          <FileUploader dropzoneEnabled={true} tags={['FinancialPage']} onUploadSuccess={onUpload} />
        )}
        <Button
          onClick={() => {
            setIsManual(true)
          }}
        >
          Enter Manually
        </Button>
      </GlassCard>
    )
  }
  return <FinancialInput submit={submit} initState={(opportunity.financialDataV2 || []).map((e) => ({ ...e }))} />
}

export default FinancialPage
