import '../styles/file.css'

import { Alert, Button, Flex, Loader, Text } from '@aws-amplify/ui-react'
import { ChangeEventHandler, useCallback, useState } from 'react'
import * as XLSX from 'xlsx'
import { useAmplifyUser } from '../contexts/amplifyUserContext'
import { useOpportunity } from '../contexts/opportunityContext'
import { dividendTypeOptions, existingCapTableColumns, getCapTableCells, participationTypeOptions, shareTypeOptions } from '../inputTableInfo'
import { exportExcel } from '../services/excel'
import { PB_CAP_TABLE_INPUT, enabledForOrg } from '../services/featureFlags'
import { deleteFile, listFilesForOpportunity, uploadFileForOpportunity } from '../services/fileStorage'
import { restAPIRequest } from '../services/restApiRequest'
import { CellValues } from '../sheets'
import { ExistingOption, ExistingShareHolder, ExistingWarrant, ShareHolderType } from '../types'
import ExcelIcon from '../ui-components/ExcelIcon'
import { DELIM, isDateValid, parseRestError, uniqValue } from '../utils/utils'
import InputTable from './InputTable'
import PublicCapTableUploader from './PublicCapTableUploader'

type CapTableUploadProps = {
  onUpload: (x: ExistingShareHolder[]) => void
  onChange: (x: ExistingShareHolder[]) => void
  onSave?: () => void
  data: ExistingShareHolder[]
}

export function formatShareholderType(typeIn: string): ShareHolderType['type'] {
  typeIn = typeIn ?? ''
  if (typeIn.includes('SHARE_HOLDER_TYPE')) return typeIn as ShareHolderType['type']
  let type: ShareHolderType['type']
  if (typeIn === 'Warrant') {
    type = 'SHARE_HOLDER_TYPE_WARRANT'
  } else if (typeIn === 'Preferred') {
    type = 'SHARE_HOLDER_TYPE_SERIES'
  } else if (typeIn === 'Option') {
    type = 'SHARE_HOLDER_TYPE_OPTION'
  } else {
    type = 'SHARE_HOLDER_TYPE_COMMON'
  }

  return type
}

export function formatParticipationType(type: string): ShareHolderType['participationType'] {
  type = type ?? ''
  if (type.includes('PARTICIPATION_RIGHTS')) return type as ShareHolderType['participationType']
  let participationType: ShareHolderType['participationType']
  if (type === 'Non Participating') {
    participationType = 'PARTICIPATION_RIGHTS_NON_PARTICIPATING'
  } else if (type === 'Full Participating') {
    participationType = 'PARTICIPATION_RIGHTS_PARTICIPATING_FULL'
  } else if (type === 'Participating Preferred with Cap') {
    participationType = 'PARTICIPATION_RIGHTS_PARTICIPATING_WITH_CAP'
  } else {
    participationType = 'PARTICIPATION_RIGHTS_NA'
  }

  return participationType
}

export function formatDividend(type: string): ShareHolderType['dividendType'] {
  type = type ?? ''
  if (type.includes('DIVIDEND')) return type as ShareHolderType['dividendType']
  let dividendType: ShareHolderType['dividendType']
  if (type === 'Non Cumulative') {
    dividendType = 'DIVIDEND_NON_CUMULATIVE'
  } else if (type === 'Cumulative') {
    dividendType = 'DIVIDEND_CUMULATIVE'
  } else if (type === 'Not Declared') {
    dividendType = 'DIVIDEND_NOT_DECLARED'
  } else {
    dividendType = 'DIVIDEND_NA'
  }

  return dividendType
}

type ParseCapTableRes = {
  existingCapTable: ExistingShareHolder[]
}

const CapTableUpload = ({ onUpload, onChange, data, onSave }: CapTableUploadProps) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const opportunity = useOpportunity()
  const amplifyUser = useAmplifyUser()
  const orgGroups = amplifyUser?.groups
  const pbCaptableEnabled = enabledForOrg(orgGroups, PB_CAP_TABLE_INPUT)

  const onFileChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    var file = e.target?.files?.[0]
    if (!file) {
      return alert('select a file')
    }
    if (loading) return alert('already uploading')

    try {
      if (opportunity) {
        const files = await listFilesForOpportunity(opportunity, 'capTable')
        for (const { path } of files) {
          if (!path) continue
          await deleteFile(path)
        }
        await uploadFileForOpportunity(opportunity, file, {
          path: 'capTable',
        })
      }
    } catch (error: any) {
      console.error('Error saving Cap Table file to S3', parseRestError(error, 'UNKNOWN'))
    }
    setLoading(true)
    var reader = new FileReader()
    reader.onload = async function (e) {
      var data = e?.target?.result
      if (!data) {
        return alert('invalid file, could not read')
      }

      var workbook = XLSX.read(data, {
        cellDates: true,
      })
      try {
        try {
          const aoa = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1, blankrows: false }) as CellValues[][]
          const data = makeCapTable(aoa, new Date())
          onUpload(data)
        } catch (error) {
          const body = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]])
          try {
            const parseRes = await restAPIRequest<ParseCapTableRes>({
              path: 'ai/parseCapTable',
              method: 'post',
              body: {
                uploadedData: body,
              },
            })
            onUpload(parseRes.existingCapTable)
          } catch (error) {
            console.error('Error parsing Cap Table', parseRestError(error, 'UNKNOWN'))
            alert('Error parsing Cap Table. Try uploading a different file.')
          }
        }
      } finally {
        setLoading(false)
      }
    }
    reader.readAsArrayBuffer(file)
  }

  const uploadInput = () => {
    if (loading) {
      return (
        <Flex>
          <Loader />
          <Text>Loading Cap Table...</Text>
        </Flex>
      )
    }
    return <input type='file' accept='.csv, .xlsx, .xls, .txt, .text, .log, .md, text/*' onChange={onFileChange} />
  }

  const deleteRow = useCallback(
    (rowIndex: number) => {
      onChange(data.filter((_, i) => i !== rowIndex))
    },
    [data, onChange]
  )

  const getErrors = useCallback(() => {
    return { errorMessage: '', hasError: false }
  }, [])

  const getOptions = useCallback((type: string) => {
    if (type === 'ShareTypes') {
      return shareTypeOptions
    } else if (type === 'ParticipationTypes') {
      return participationTypeOptions
    } else if (type === 'DividendTypes') {
      return dividendTypeOptions
    }

    return []
  }, [])

  const updateData = useCallback(
    (rowIndex: number, columnId: string, value: unknown): void => {
      const out = data.map((row, index) => {
        if (index !== rowIndex) {
          return row
        }

        const o = {
          ...row,
          [columnId]: value,
        }
        return o
      })
      const dup = findDuplicateNameGroupPairs(out)
      setError('')
      if (dup) {
        setError(`"${dup}" has duplicate entires. Please change the name or group to continue.`)
      }
      onChange(out)
    },
    [data, onChange]
  )

  const addItemClick = () => {
    onChange([
      ...data,
      {
        type: 'SHARE_HOLDER_TYPE_SERIES',
        group: 'Existing Investors',
        shareholderName: uniqValue(
          'Shareholder',
          data.map((i) => i.shareholderName)
        ),
        shareCount: 1,
        pricePerShare: 0.01,
        conversionRatio: 1,
        liquidationMultiple: 1,
        participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
        capOnParticipation: 1,
        investmentDate: new Date(),
        exitDate: new Date(),
        dividendType: 'DIVIDEND_NA',
        interestRate: 0,
      },
    ])
  }

  function submitPublicCap(): void {
    const fake = [
      {
        type: 'SHARE_HOLDER_TYPE_SERIES',
        exitDate: new Date(),
        shareholderName: 'Foundation Capital',
        group: 'Series A',
        shareCount: 4520755,
        conversionRatio: 1,
        pricePerShare: 0.89,
        strikePrice: 0,
        liquidationMultiple: 1,
        investmentDate: new Date('2018-06-07'),
        dividendType: 'DIVIDEND_NA',
        interestRate: 0,
        participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
      },
      {
        type: 'SHARE_HOLDER_TYPE_SERIES',
        exitDate: new Date(),
        shareholderName: 'Foundation Capital',
        group: 'Series B',
        shareCount: 2102067,
        conversionRatio: 1,
        pricePerShare: 3.44,
        strikePrice: 0,
        liquidationMultiple: 1,
        investmentDate: new Date('2020-06-07'),
        dividendType: 'DIVIDEND_NA',
        interestRate: 0,
        participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
      },
      {
        type: 'SHARE_HOLDER_TYPE_SERIES',
        exitDate: new Date(),
        shareholderName: 'Foundation Capital',
        group: 'Series C',
        shareCount: 1022629,
        conversionRatio: 1,
        pricePerShare: 10.69,
        strikePrice: 0,
        liquidationMultiple: 1,
        investmentDate: new Date('2022-06-07'),
        dividendType: 'DIVIDEND_NA',
        interestRate: 0,
        participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
      },
      {
        type: 'SHARE_HOLDER_TYPE_SERIES',
        exitDate: new Date(),
        shareholderName: 'NeoTribe Ventures',
        group: 'Series A',
        shareCount: 4520755,
        conversionRatio: 1,
        pricePerShare: 0.89,
        strikePrice: 0,
        liquidationMultiple: 1,
        investmentDate: new Date('2018-06-07'),
        dividendType: 'DIVIDEND_NA',
        interestRate: 0,
        participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
      },
      {
        type: 'SHARE_HOLDER_TYPE_SERIES',
        exitDate: new Date(),
        shareholderName: 'NeoTribe Ventures',
        group: 'Series B',
        shareCount: 2102067,
        conversionRatio: 1,
        pricePerShare: 3.44,
        strikePrice: 0,
        liquidationMultiple: 1,
        investmentDate: new Date('2020-06-07'),
        dividendType: 'DIVIDEND_NA',
        interestRate: 0,
        participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
      },
      {
        type: 'SHARE_HOLDER_TYPE_SERIES',
        exitDate: new Date(),
        shareholderName: 'NeoTribe Ventures',
        group: 'Series C',
        shareCount: 1022629,
        conversionRatio: 1,
        pricePerShare: 10.69,
        strikePrice: 0,
        liquidationMultiple: 1,
        investmentDate: new Date('2022-06-07'),
        dividendType: 'DIVIDEND_NA',
        interestRate: 0,
        participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
      },
      {
        type: 'SHARE_HOLDER_TYPE_SERIES',
        exitDate: new Date(),
        shareholderName: 'Intel Capital',
        group: 'Series B',
        shareCount: 2102067,
        conversionRatio: 1,
        pricePerShare: 3.44,
        strikePrice: 0,
        liquidationMultiple: 1,
        investmentDate: new Date('2020-06-07'),
        dividendType: 'DIVIDEND_NA',
        interestRate: 0,
        participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
      },
      {
        type: 'SHARE_HOLDER_TYPE_SERIES',
        exitDate: new Date(),
        shareholderName: 'Intel Capital',
        group: 'Series C',
        shareCount: 1022629,
        conversionRatio: 1,
        pricePerShare: 10.69,
        strikePrice: 0,
        liquidationMultiple: 1,
        investmentDate: new Date('2022-06-07'),
        dividendType: 'DIVIDEND_NA',
        interestRate: 0,
        participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
      },
      {
        type: 'SHARE_HOLDER_TYPE_SERIES',
        exitDate: new Date(),
        shareholderName: 'GiantLeap Capital',
        group: 'Series C',
        shareCount: 1022629,
        conversionRatio: 1,
        pricePerShare: 10.69,
        strikePrice: 0,
        liquidationMultiple: 1,
        investmentDate: new Date('2022-06-07'),
        dividendType: 'DIVIDEND_NA',
        interestRate: 0,
        participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
      },
      {
        type: 'SHARE_HOLDER_TYPE_SERIES',
        exitDate: new Date(),
        shareholderName: 'Goldman Sachs Asset Management',
        group: 'Series C',
        shareCount: 2556572,
        conversionRatio: 1,
        pricePerShare: 10.06,
        strikePrice: 0,
        liquidationMultiple: 1,
        investmentDate: new Date('2022-06-07'),
        dividendType: 'DIVIDEND_NOT_DECLARED',
        interestRate: 0.08,
        participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
        capOnParticipation: 1.5,
      },
      {
        type: 'SHARE_HOLDER_TYPE_SERIES',
        exitDate: new Date(),
        shareholderName: 'In-Q-Tel',
        group: 'Series C',
        shareCount: 1022629,
        conversionRatio: 1,
        pricePerShare: 10.69,
        strikePrice: 0,
        liquidationMultiple: 1,
        investmentDate: new Date('2022-06-07'),
        dividendType: 'DIVIDEND_NA',
        interestRate: 0,
        participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
      },
      {
        type: 'SHARE_HOLDER_TYPE_COMMON',
        exitDate: new Date(),
        shareholderName: 'Common',
        group: 'Common',
        shareCount: 9820197,
        conversionRatio: 1,
        pricePerShare: 0,
        strikePrice: 0,
        liquidationMultiple: 1,
        investmentDate: new Date('2020-06-07'),
        dividendType: 'DIVIDEND_NA',
        interestRate: 0,
        participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
      },
      {
        type: 'SHARE_HOLDER_TYPE_OPTION',
        exitDate: new Date(),
        shareholderName: 'Option',
        group: 'Option',
        shareCount: 5794875,
        strikePrice: 0.01,
        interestRate: 1,
        investmentDate: new Date('2020-06-07'),
      },
    ] as ExistingShareHolder[]
    setLoading(true)
    onUpload(fake)
    setTimeout(() => setLoading(false), 300)
  }

  const exportToExcelButton = (
    <Button
      variation='primary'
      size='small'
      gap='0.5rem'
      alignSelf='flex-end'
      className='excel'
      onClick={() => {
        const TypeToHuman = {
          SHARE_HOLDER_TYPE_SERIES: 'Preferred',
          SHARE_HOLDER_TYPE_COMMON: 'Common',
          SHARE_HOLDER_TYPE_OPTION: 'Option',
          SHARE_HOLDER_TYPE_WARRANT: 'Warrant',
        }
        const DividendToHuman = {
          DIVIDEND_CUMULATIVE: 'Cumulative',
          DIVIDEND_NON_CUMULATIVE: 'Non-Cumulative',
          DIVIDEND_NA: 'N/A',
        }
        const ParticipationTypeToHuman = {
          PARTICIPATION_RIGHTS_NON_PARTICIPATING: 'Convertible Preferred',
          PARTICIPATION_RIGHTS_PARTICIPATING_FULL: 'Participating Preferred',
          PARTICIPATION_RIGHTS_PARTICIPATING_WITH_CAP: 'Participating w/ Cap',
          PARTICIPATION_RIGHTS_NA: 'N/A',
        }
        const info = data.map((n: any) => ({
          'Shareholder Name': n.shareholderName,
          'Investor Group': n.group,
          'Shareholder Type': TypeToHuman[n.type],
          'Fully Diluted Share Count': n.shareCount,
          'Amount Invested': n.amountInvested || n.shareCount * n.pricePerShare,
          'Investment Date': formatDate(n.investmentDate),
          'Price Per Share': n.pricePerShare,
          'Strike Price': n.strikePrice,
          'Liquidation Multiple': n.liquidationMultiple,
          'Dividend Type': DividendToHuman[n.dividendType],
          'Dividends (%)': n.interestRate,
          'Participation Type': ParticipationTypeToHuman[n.participationType],
          'Cap on Participation (As MoM)': n.capOnParticipation,
          'Conversion Ratio': n.conversionRatio,
          Seniority: n.seniority,
        }))

        function formatDate(date: string): string {
          const d = new Date(date)
          const day = d.getDate()
          const month = d.getMonth() + 1
          const year = d.getFullYear()
          return `${month}/${day}/${year}`
        }
        exportExcel({
          sheetName: 'Playbook Cap Table',
          filename: `Playbook Cap Table.xlsx`,
          data: info,
        })
      }}
    >
      <ExcelIcon />
      Export to Excel
    </Button>
  )
  return (
    <Flex direction='column'>
      {error && <Alert variation='error'>{error}</Alert>}
      <Flex alignItems='center'>
        <Button size='small' display={opportunity?.name === 'fortanix' ? '' : 'none'} isLoading={loading} onClick={() => submitPublicCap()}>
          Use Publicly Available
        </Button>
        <Text className='amplify-label' margin='1rem 0 1rem 0' fontWeight='600'>
          Upload Existing Cap Table
        </Text>
        {uploadInput()}
        {pbCaptableEnabled ? (
          <PublicCapTableUploader
            opportunityName={opportunity?.name}
            onUpload={(cp) => {
              onUpload(cp)
              setError('')
            }}
            onError={(err) => setError(err)}
          />
        ) : null}
        {exportToExcelButton}
        {onSave && <Button onClick={onSave}>Save</Button>}
      </Flex>
      <InputTable
        data={data}
        columns={existingCapTableColumns}
        updateData={updateData}
        deleteRow={deleteRow}
        getOptions={getOptions}
        getErrors={getErrors}
        getCell={getCapTableCells}
      />
      <Button isLoading={loading} onClick={addItemClick} size='small' alignSelf='flex-start' margin='0 0 1rem 0'>
        + Add Existing Investment
      </Button>
    </Flex>
  )
}

const findDuplicateNameGroupPairs = (data: ExistingShareHolder[]): string | undefined => {
  const found = data.map((i) => `${i.shareholderName}${DELIM}${i.group}`).find((predicate, index, array) => array.indexOf(predicate) !== index)
  if (!found) return undefined
  return found.split(DELIM).join(' - ')
}

function createTypes<T extends string>(types: Record<string, T>): Record<string, T> {
  return types
}

type ShareType = 'SHARE_HOLDER_TYPE_SERIES' | 'SHARE_HOLDER_TYPE_COMMON' | 'SHARE_HOLDER_TYPE_OPTION' | 'SHARE_HOLDER_TYPE_WARRANT'

const ShareTypes = createTypes<ShareType>({
  Preferred: 'SHARE_HOLDER_TYPE_SERIES',
  Common: 'SHARE_HOLDER_TYPE_COMMON',
  Option: 'SHARE_HOLDER_TYPE_OPTION',
  Warrant: 'SHARE_HOLDER_TYPE_WARRANT',
})

export const ShareKeys = Object.freeze(
  Object.keys(ShareTypes).reduce(
    (p, c) => ({
      ...p,
      [ShareTypes[c]]: c,
    }),
    {} as Record<ShareType, string>
  )
)

type ParticipationType = 'PARTICIPATION_RIGHTS_NON_PARTICIPATING' | 'PARTICIPATION_RIGHTS_PARTICIPATING_FULL' | 'PARTICIPATION_RIGHTS_PARTICIPATING_WITH_CAP'

export const ParticipationTypes = createTypes<ParticipationType>({
  'Convertible Preferred': 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
  'Participating Preferred': 'PARTICIPATION_RIGHTS_PARTICIPATING_FULL',
  'Participating w/ Cap': 'PARTICIPATION_RIGHTS_PARTICIPATING_WITH_CAP',
})

export const ParticipationKeys = Object.freeze(
  Object.keys(ParticipationTypes).reduce(
    (p, c) => ({
      ...p,
      [ParticipationTypes[c]]: c,
    }),
    {} as Record<ParticipationType, string>
  )
)

type DividendType = 'DIVIDEND_CUMULATIVE' | 'DIVIDEND_NON_CUMULATIVE' | 'DIVIDEND_NA'

export const DividendTypes = createTypes<DividendType>({
  Cumulative: 'DIVIDEND_CUMULATIVE' as DividendType,
  'Non-Cumulative': 'DIVIDEND_NON_CUMULATIVE' as DividendType,
  'N/A': 'DIVIDEND_NA' as DividendType,
})

export const DividendKeys = Object.freeze(
  Object.keys(DividendTypes).reduce(
    (p, c) => ({
      ...p,
      [DividendTypes[c]]: c,
    }),
    {} as Record<DividendType, string>
  )
)

export type HeaderKeys =
  | 'shareholderName'
  | 'group'
  | 'type'
  | 'shareCount'
  | 'amountInvested'
  | 'investmentDate'
  | 'pricePerShare'
  | 'strikePrice'
  | 'liquidationMultiple'
  | 'dividendType'
  | 'interestRate'
  | 'participationType'
  | 'capOnParticipation'
  | 'conversionRatio'
  | 'seniority'

export const expectedHeaders: {
  label: string
  key: HeaderKeys
}[] = [
  { label: 'Shareholder Name', key: 'shareholderName' },
  { label: 'Investor Group', key: 'group' },
  { label: 'Shareholder Type', key: 'type' },
  { label: 'Fully Diluted Share Count', key: 'shareCount' },
  { label: 'Amount Invested', key: 'amountInvested' },
  { label: 'Investment Date', key: 'investmentDate' },
  { label: 'Price Per Share', key: 'pricePerShare' },
  { label: 'Strike Price', key: 'strikePrice' },
  { label: 'Liquidation Multiple', key: 'liquidationMultiple' },
  { label: 'Dividend Type', key: 'dividendType' },
  { label: 'Dividends (%)', key: 'interestRate' },
  { label: 'Participation Type', key: 'participationType' },
  { label: 'Cap on Participation (As MoM)', key: 'capOnParticipation' },
  { label: 'Conversion Ratio', key: 'conversionRatio' },
  { label: 'Seniority', key: 'seniority' },
]

export type ExtraHeaderKeys = 'convertTo.shareholderName' | 'convertTo.group' | 'exerciseDate'

export const extraHeaders: {
  label: string
  key: ExtraHeaderKeys
}[] = [
  { label: 'Convert to Shareholder Name', key: 'convertTo.shareholderName' },
  { label: 'Convert to Investor Group', key: 'convertTo.group' },
  { label: 'Exercise Date', key: 'exerciseDate' },
]

export const HeaderLabels = Object.freeze(
  expectedHeaders.reduce(
    (p, c) => ({
      ...p,
      [c.key]: c.label,
    }),
    {} as Record<HeaderKeys, string>
  )
)

export const ExtraHeaderLabels = Object.freeze(
  extraHeaders.reduce(
    (p, c) => ({
      ...p,
      [c.key]: c.label,
    }),
    {} as Record<ExtraHeaderKeys, string>
  )
)

function getIndexForItem<T extends keyof ShareHolderType>(type: T): number {
  const index = expectedHeaders.findIndex((header) => header.key === type)
  if (index === -1) throw new Error(`Invalid type ${type}`)
  return index
}

function extraItem<T extends keyof ShareHolderType>(headers: CellValues[], row: CellValues[], type: ExtraHeaderKeys): ShareHolderType[T] | undefined {
  const label = ExtraHeaderLabels[type]
  const index = headers.findIndex((header) => header === label)
  if (index === -1) return undefined
  let out = row[index] as any
  if (typeof out === 'string') out = out.trim()
  return out
}

function item<T extends keyof ShareHolderType>(row: CellValues[], type: T): ShareHolderType[T] {
  const index = getIndexForItem(type)
  let out = row[index] as any
  if (typeof out === 'string') out = out.trim()
  return out
}

function checkExtraHeaders(data: CellValues[][]): void {
  const headerRow = data[0]
  const seen: Partial<Record<ExtraHeaderKeys, number>> = {}
  if (headerRow.length <= expectedHeaders.length) return
  for (let i = expectedHeaders.length; i < expectedHeaders.length + extraHeaders.length; i++) {
    const header = headerRow[i] as string
    if (!header) continue
    const validLabel = extraHeaders.find((cell) => cell.label.toLowerCase() === header.toLowerCase())
    if (!validLabel) {
      throw new Error(`Header "${header}" is not valid. Valid headers are: ${extraHeaders.map((h) => h.label).join(', ')}`)
    }
    if (seen[validLabel.key]) {
      throw new Error(`Duplicate header found: ${header} in column ${i}. Duplicate header in column ${seen[header]}.`)
    }
    seen[validLabel.key] = i
  }

  if (seen['convertTo.group'] && !seen['convertTo.shareholderName']) {
    throw new Error(`"Convert to Shareholder Name" is missing. If "Convert to Investor Group" is present, "Convert to Shareholder Name" must be present as well.`)
  }
  if (seen['convertTo.shareholderName'] && !seen['convertTo.group']) {
    throw new Error(`"Convert to Investor Group" is missing. If "Convert to Shareholder Name" is present, "Convert to Investor Group" must be present as well.`)
  }
}

export function makeCapTable(data: CellValues[][], exitDate: Date): ExistingShareHolder[] {
  const capTable: ExistingShareHolder[] = []

  if (data[0].length < expectedHeaders.length) {
    throw new Error(
      'Invalid number of columns. Expected at least' +
        expectedHeaders.length +
        ' but got ' +
        data[0].length +
        '\n' +
        '\n' +
        '. Required columns in order: ' +
        expectedHeaders.map((h) => h.label).join(', ') +
        '.'
    )
  }

  for (let i = 0; i < expectedHeaders.length; i++) {
    if ((data[0][i] as string)?.toLowerCase() !== expectedHeaders[i].label.toLowerCase()) {
      throw new Error('Invalid header at column ' + i + '. Expected ' + expectedHeaders[i].label + ' but got ' + data[0][i])
    }
  }

  checkExtraHeaders(data)
  const headers = data[0]

  for (let i = 1; i < data.length; i++) {
    const row = data[i]
    const shareholderName = item(row, 'shareholderName')
    if (!shareholderName) throw new Error(`Shareholder name missing from row ${i}`)
    const group = item(row, 'group')
    if (!group) throw new Error(`"Investor Group" missing from row ${i}`)

    const rawType = item(row, 'type')
    if (!rawType) throw new Error(`"Shareholder Type" missing from row ${i}: ${shareholderName} - ${group}`)
    const type = ShareTypes[rawType]
    if (!type)
      throw new Error(`Invalid "Type" for row ${i}: ${shareholderName} - ${group}. "Shareholder Type" should be one of ${Object.keys(ShareTypes).join(', ')}. Got {rawType`)
    const shareCount = item(row, 'shareCount')
    if (!shareCount) throw new Error(`"Share Count" missing from row ${i}: ${shareholderName} - ${group}`)
    if (shareCount <= 0) throw new Error(`"Share Count" must be more then 0 for row ${i}: ${shareholderName} - ${group}`)

    const pricePerShare = item(row, 'pricePerShare')
    if (type === ShareTypes.Series) {
      if (!pricePerShare) throw new Error(`"Price per Share" missing from row ${i}: ${shareholderName} - ${group}`)
      if (pricePerShare <= 0) throw new Error(`"Price per Share" must be more than 0 for row ${i}: ${shareholderName} - ${group}`)
    }

    const strikePrice = item(row, 'strikePrice')
    if (type === ShareTypes.Option || type === ShareTypes.Warrant) {
      if (!strikePrice) throw new Error(`"Strike price" missing from row ${i}: ${shareholderName} - ${group}`)
      if (strikePrice <= 0) throw new Error(`Strike price must be more than 0 for row ${i}: ${shareholderName} - ${group}`)
    }

    const amountInvested = item(row, 'amountInvested')
    if (![ShareTypes.Common, ShareTypes.Warrant, ShareTypes.Option].includes(type)) {
      if (!amountInvested) throw new Error(`"Amount invested" missing from row ${i}: ${shareholderName} - ${group}`)
      if (amountInvested <= 0) throw new Error(`Amount invested must be more than 0 for row ${i}: ${shareholderName} - ${group}`)
    }

    const conversionRatio = item(row, 'conversionRatio')
    if (type === ShareTypes.Series) {
      if (!conversionRatio) throw new Error(`"Conversion Ratio" missing from row ${i}: ${shareholderName} - ${group}`)
      if (conversionRatio <= 0) throw new Error(`"Conversion Ratio" must be more than 0 for row ${i}: ${shareholderName} - ${group}`)
    }

    const investmentDateRaw = item(row, 'investmentDate')
    if (!investmentDateRaw) throw new Error(`"Investment Date" missing from row ${i}: ${shareholderName} - ${group}`)
    const investmentDate = convertExcelDate(investmentDateRaw)

    if (!isDateValid(investmentDate)) throw new Error(`"Investment Date" is invalid for row ${i}: ${shareholderName} - ${group}`)

    const dividendTypeRaw = item(row, 'dividendType')
    let dividendType = DividendTypes[dividendTypeRaw]
    if (dividendType) {
      if (!dividendType)
        throw new Error(
          `Invalid dividend type for row ${i}: ${shareholderName} - ${group}. "Dividend Type" must be one of ${Object.keys(DividendTypes).join(', ')}. Got {dividendTypeRaw`
        )
    } else {
      dividendType = DividendTypes['N/A']
    }

    const interestRate = item(row, 'interestRate') || 0
    if (dividendType !== DividendTypes['N/A'] && !interestRate) throw new Error(`"Dividend Type" is set but "Interest Rate" missing from row ${i}: ${shareholderName} - ${group}`)

    if (interestRate && interestRate <= 0) throw new Error(`"Interest Rate" must be more then 0 for row ${i}: ${shareholderName} - ${group}. "Interest Rate" is ${interestRate}`)

    const participationTypeRaw = item(row, 'participationType')
    const participationType = ParticipationTypes[participationTypeRaw]
    if (type === ShareTypes.Series && !participationType)
      throw new Error(
        `Invalid "Participation Type" for row ${i}: ${shareholderName} - ${group}. "Participation Type" should be one of ${Object.keys(ParticipationTypes).join(
          ', '
        )}. Got {participationTypeRaw`
      )

    const capOnParticipation = item(row, 'capOnParticipation')
    if (participationType === ParticipationTypes['Participating w/ Cap'] && !capOnParticipation)
      throw new Error(`"Cap on Participation" is missing from row ${i}: ${shareholderName} - ${group}`)

    const seniority = item(row, 'seniority')
    if (!seniority) throw new Error(`"Seniority" missing from row ${i}: ${shareholderName} - ${group}`)

    const liquidationMultiple = item(row, 'liquidationMultiple')
    if (type === ShareTypes.Series && !liquidationMultiple) throw new Error(`"Liquidation Multiple" must be more then 0 for row ${i}: ${shareholderName} - ${group}`)

    const capTableRow = {
      shareholderName,
      group,
      type,
      shareCount,
      pricePerShare,
      strikePrice,
      amountInvested,
      conversionRatio,
      investmentDate,
      exitDate,
      dividendType,
      interestRate,
      liquidationMultiple,
      participationType,
      capOnParticipation,
      seniority,
    } as ExistingShareHolder

    const shareholderNameConvert = extraItem(headers, row, 'convertTo.shareholderName') as string
    const groupConvert = extraItem(headers, row, 'convertTo.group') as string
    const exerciseDateRaw = extraItem(headers, row, 'exerciseDate') as string

    if (type === ShareTypes.Option || type === ShareTypes.Warrant) {
      if (shareholderNameConvert || groupConvert) {
        if (!shareholderNameConvert) throw new Error(`"Convert to Shareholder Name" is missing for row ${i}: ${shareholderName} - ${group}`)
        if (!groupConvert) throw new Error(`"Convert to Investor Group" is missing for row ${i}: ${shareholderName} - ${group}`)

        const groupIndex = getIndexForItem('group')
        const shareholderNameIndex = getIndexForItem('shareholderName')

        const hasMatch = data.find((d) => d[shareholderNameIndex] === shareholderNameConvert && d[groupIndex] === groupConvert)
        if (!hasMatch) {
          throw new Error(
            `Did not find a security to convert to based on for row ${i}: ${shareholderName} - ${group}. Ensure "Convert to Shareholder Name" and "Convert to Investor Group" match an existing security exactly.`
          )
        }

        ;(capTableRow as ExistingOption | ExistingWarrant).convertTo = {
          shareholderName: shareholderNameConvert,
          group: groupConvert,
        }
      }

      if (exerciseDateRaw) {
        const exerciseDate = convertExcelDate(exerciseDateRaw)
        if (!isDateValid(exerciseDate)) throw new Error(`"Exercise Date" is invalid for row ${i}: ${shareholderName} - ${group}`)
        ;(capTableRow as ExistingOption | ExistingWarrant).exerciseDate = exerciseDate
      }
    } else {
      if (shareholderNameConvert || groupConvert) {
        throw new Error(`"Convert to Shareholder Name" and "Convert to Investor Group" are only valid for "Option" and "Warrant" types. Row ${i}: ${shareholderName} - ${group}`)
      }
      if (exerciseDateRaw) {
        throw new Error(`"Exercise Date" is only valid for "Option" and "Warrant" types. Row ${i}: ${shareholderName} - ${group}`)
      }
    }
    capTable.push(capTableRow)
  }

  // check if there are any duplicate shareholderName-group pairs
  for (const row of capTable) {
    const duplicates = capTable.filter((c) => {
      return c.shareholderName === row.shareholderName && c.group === row.group
    })
    if (duplicates.length > 1) {
      // find all matching indexes
      const indexes = duplicates.map((d) => capTable.indexOf(d) + 2)
      throw new Error(`Duplicate "Shareholder Name" - "Investor Group" pair found for ${row.shareholderName} - ${row.group}. Rows: ${indexes.join(', ')}`)
    }
  }
  return capTable
}

function convertExcelDate(excelDate: number | string | Date): Date {
  let d
  if (typeof excelDate === 'number') {
    d = new Date((excelDate - (25567 + 2)) * 86400 * 1000)
  } else {
    d = new Date(excelDate)
  }
  d.setUTCHours(0, 0, 0, 0)
  return d
}

export default CapTableUpload
