import { BaseStyleProps, Card, Flex, View, Text, Loader } from '@aws-amplify/ui-react'
import React, { FunctionComponent, Suspense, useState } from 'react'
import { CkChevronDown, CkChevronUp } from '../ui-components'

interface GlassCardProps {
  children?: React.ReactNode
  header: string
  level?: 0 | 1 | 2
  expandable?: boolean
  headerButtons?: React.ReactNode
  headerIcon?: React.ReactNode
  headerProps?: BaseStyleProps
  initIsExpanded?: boolean
  columnStart?: string
  columnEnd?: string
  rowStart?: string
  rowEnd?: string
  className?: string
  [x: string]: any
}

const GlassCard: FunctionComponent<GlassCardProps> = ({
  expandable,
  header,
  children,
  headerButtons,
  headerIcon,
  headerProps,
  level = 1,
  initIsExpanded = false,
  className,
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = useState(initIsExpanded)
  const icon = isExpanded ? <CkChevronUp /> : <CkChevronDown />
  // Render header
  let headerRow: React.ReactNode
  if (header.length === 0) {
    headerRow = <></>
  } else {
    const headerSize = level === 0 ? 'header3' : level === 1 ? 'header4' : 'header6'
    const removeFormatting = header.replace(/\*\*/gm, '').replace(/__/g, '')
    const headerText = <Text className={headerSize}>{removeFormatting}</Text>
    headerRow = headerText
    if (expandable) {
      headerRow = (
        <Flex justifyContent='space-between' style={{ cursor: 'pointer' }} onClick={() => setIsExpanded(!isExpanded)}>
          {headerText}
          {icon}
        </Flex>
      )
    }
    if (headerButtons || headerIcon || headerProps) {
      headerRow = (
        <Flex justifyContent='space-between' alignItems='center' style={{ cursor: 'pointer' }} onClick={() => setIsExpanded(!isExpanded)} {...headerProps}>
          <Flex alignItems='center'>
            {headerIcon && headerIcon}
            {headerText}
          </Flex>
          <View marginLeft='auto'>{headerButtons && headerButtons}</View>
          {expandable && icon}
        </Flex>
      )
    }
  }

  const showChildren = expandable ? isExpanded : true
  const child = showChildren ? children : null
  return (
    <Card className={`baseCard ${className ? className : ''}`} onClick={() => !isExpanded && setIsExpanded(!isExpanded)} {...rest}>
      <Flex direction='column' className='cardContent'>
        {headerRow}
        <Suspense fallback={<Loader />}>{child}</Suspense>
      </Flex>
    </Card>
  )
}

export default GlassCard
