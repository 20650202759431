import { FunctionComponent } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useOpportunity } from '../contexts/opportunityContext'
import { useCompanyInfoV2 } from '../hooks/useCompanyInfoV2'
import { Flex, Heading, Button, Image, Loader } from '@aws-amplify/ui-react'
import { CompanyIcon, TransactionModelIcon, MetricsBenchmarkingIcon, ValuationCompsIcon, FilesIcon } from '../ui-components'
import { getPathTo, DEAL, TRANSACTION_MODEL, VALUATION, TARGET_PUBLIC_BENCHMARK, DOC_AI } from '../sitemap'
import GlassCard from '../figma-components/GlassCard'
import '../styles/model.scss'

const CompanyHeader: FunctionComponent = () => {
  const opportunity = useOpportunity()
  const [companyInfo, loading] = useCompanyInfoV2(opportunity?.name)
  const navigate = useNavigate()
  const location = useLocation()

  if (loading || !companyInfo) return <Loader />
  
  const companyLogoImg = companyInfo.iconUrl ? <Image alt='company image' src={companyInfo.iconUrl} maxHeight={80} borderRadius='0.5rem' /> : <></>
  const buttons = (
    <>
      <Button
        size='small'
        className={`tooltip down ${location.pathname.includes('profile') ? 'active' : ''}`}
        data-tooltip='Profile'
        onClick={() => navigate(getPathTo(DEAL, { opportunityId: opportunity?.id }))}
      >
        <CompanyIcon />
      </Button>
      <Button
        size='small'
        className={`tooltip down ${location.pathname.includes('financials') ? 'active' : ''}`}
        data-tooltip='Financials'
        onClick={() => navigate(getPathTo(TARGET_PUBLIC_BENCHMARK, { opportunityId: opportunity?.id }))}
      >
        <TransactionModelIcon />
      </Button>
      <Button
        size='small'
        className={`tooltip down ${location.pathname.includes('valuation') ? 'active' : ''}`}
        data-tooltip='Valuation'
        onClick={() => navigate(getPathTo(VALUATION, { opportunityId: opportunity?.id }))}
      >
        <MetricsBenchmarkingIcon />
      </Button>
      <Button
        size='small'
        className={`tooltip down ${location.pathname.includes('transaction_model') ? 'active' : ''}`}
        data-tooltip='Transaction Model'
        onClick={() => navigate(getPathTo(TRANSACTION_MODEL, { opportunityId: opportunity?.id }))}
      >
        <ValuationCompsIcon />
      </Button>
      <Button
        size='small'
        className={`tooltip down ${location.pathname.includes('document_chat') ? 'active' : ''}`}
        data-tooltip='Document AI'
        onClick={() => navigate(getPathTo(DOC_AI, { opportunityId: opportunity?.id }))}
      >
        <FilesIcon />
      </Button>
    </>
  )

  return (
    <GlassCard header='' className='companyHeaderCard'>
      <Flex direction='row' alignItems='center' justifyContent='space-between'>
        <Flex direction='row' alignItems='center'>
          {companyLogoImg}
          <Flex direction='column' gap='0px'>
            <Heading level={3} fontSize='2rem' fontWeight='700'>
              {companyInfo.companyLabel} - {opportunity?.transactionName}
            </Heading>
          </Flex>
        </Flex>
        <Flex direction='row' height='fit-content'>
          <Button
            size='small'
            className={`tooltip down ${location.pathname.includes('transaction_model') ? 'active' : ''}`}
            data-tooltip='Transaction Model'
            onClick={() => navigate(getPathTo(TRANSACTION_MODEL, { opportunityId: opportunity?.id }))}
          >
            <ValuationCompsIcon />
          </Button>
          {/* {buttons} */}
        </Flex>
      </Flex>
    </GlassCard>
  )
}

export default CompanyHeader
