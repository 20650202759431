import { Flex, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { FundingRounds } from '../types'
import { dateFormatterShort } from '../utils/utils'
import CandleStickView, { ChartData } from './CandleStickView'

interface BenchmarkingDashboardProps {
  data: FundingRounds[]
  targetData?: FundingRounds[]
}

function pushIfValid(arr: Record<string, ChartData[]>, info: FundingRounds, name: string) {
  const value = info[name]
  if (typeof value !== 'number') return
  if (value === Infinity) return

  if (value) {
    arr[name].push({ value, name: info.companyLabel || 'N/A', date: dateFormatterShort(info.date) })
  }
}
const FundingBenchmarkingDashboards: FunctionComponent<BenchmarkingDashboardProps> = ({ data, targetData = [] }) => {
  const init = {
    ntmRevGrowth: [],
    ltmRevGrowth: [],
    arrGrowthPercent: [],
    nextYearARRGrowth: [],
    netRetentionPercent: [],
    grossRetentionPercent: [],
    snmAsPercentOfRev: [],
    salesEfficiencyMultiple: [],
    implied5yrLTVPerCAC: [],
    grossMarginPercent: [],
    eBITDAMarginPercent: [],
    ruleOf40LTMEbitda: [],

    postEvLtmRev: [],
    postEvNtmRev: [],
    postEvArr: [],
    postEvNextArr: [],
    postEvLtmProfit: [],
    postEvNtmProfit: [],
    postEvLtmEbitda: [],
    postEvNtmEbitda: [],
  } as Record<string, ChartData[]>
  const initClone = JSON.parse(JSON.stringify(init))

  const chartData = data.reduce((p, c) => {
    for (const key in p) {
      pushIfValid(p, c, key)
    }
    return p
  }, init)

  const targetChartData = targetData.reduce((p, c) => {
    for (const key in p) {
      pushIfValid(p, c, key)
    }
    return p
  }, initClone)

  return (
    <Flex direction='column' maxHeight='80rem' paddingLeft='1rem' overflow='scroll'>
      <Text marginBottom='1rem' fontSize='1.25rem' fontWeight='600'>
        Operations Benchmarks
      </Text>
      <Flex direction='row' gap='1rem' wrap='wrap'>
        <CandleStickView data={chartData.ntmRevGrowth} targetData={targetChartData.ntmRevGrowth} label='NTM Rev Growth' valueType='percent' />
        <CandleStickView data={chartData.ltmRevGrowth} targetData={targetChartData.ltmRevGrowth} label='LTM Rev Growth' valueType='percent' />
        <CandleStickView data={chartData.arrGrowthPercent} targetData={targetChartData.arrGrowthPercent} label='LTM ARR Growth' valueType='percent' />
        <CandleStickView data={chartData.nextYearARRGrowth} targetData={targetChartData.nextYearARRGrowth} label='ARR FY+1 Growth' valueType='percent' />
        <CandleStickView data={chartData.netRetentionPercent} targetData={targetChartData.netRetentionPercent} label='Net $ Retention' valueType='percent' />
        <CandleStickView data={chartData.grossRetentionPercent} targetData={targetChartData.grossRetentionPercent} label='Gross $ Retention' valueType='percent' />
        <CandleStickView data={chartData.snmAsPercentOfRev} targetData={targetChartData.snmAsPercentOfRev} label='S&M as % of Rev' valueType='percent' />
        <CandleStickView data={chartData.salesEfficiencyMultiple} targetData={targetChartData.salesEfficiencyMultiple} label='Sales Efficiency' valueType='multiple' />
        <CandleStickView data={chartData.implied5yrLTVPerCAC} targetData={targetChartData.implied5yrLTVPerCAC} label='LTV / CAC' valueType='multiple' />
        <CandleStickView data={chartData.grossMarginPercent} targetData={targetChartData.grossMarginPercent} label='Gross Margin' valueType='percent' />
        <CandleStickView data={chartData.eBITDAMarginPercent} targetData={targetChartData.eBITDAMarginPercent} label='EBITDA Margin' valueType='percent' />
        <CandleStickView data={chartData.ruleOf40LTMEbitda} targetData={targetChartData.ruleOf40LTMEbitda} label='Rule of 40 LTM / EBITDA' valueType='percent' />
      </Flex>
      <Text marginBottom='1rem' fontSize='1.25rem' fontWeight='600'>
        Deal Multiple Benchmarks
      </Text>
      <Flex direction='row' gap='1rem' wrap='wrap'>
        <CandleStickView data={chartData.postEvLtmRev} targetData={targetChartData.postEvLtmRev} label='Post EV / LTM Rev' valueType='multiple' />
        <CandleStickView data={chartData.postEvNtmRev} targetData={targetChartData.postEvNtmRev} label='Post EV / NTM Rev' valueType='multiple' />
        <CandleStickView data={chartData.postEvArr} targetData={targetChartData.postEvArr} label='Post EV / ARR' valueType='multiple' />
        <CandleStickView data={chartData.postEvNextArr} targetData={targetChartData.postEvNextArr} label='Post EV / ARR FY+1' valueType='multiple' />
        <CandleStickView data={chartData.postEvLtmProfit} targetData={targetChartData.postEvLtmProfit} label='Post EV / LTM GP' valueType='multiple' />
        <CandleStickView data={chartData.postEvNtmProfit} targetData={targetChartData.postEvNtmProfit} label='Post EV / NTM GP' valueType='multiple' />
        <CandleStickView data={chartData.postEvLtmEbitda} targetData={targetChartData.postEvLtmEbitda} label='Post EV / LTM EBITDA' valueType='multiple' />
        <CandleStickView data={chartData.postEvNtmEbitda} targetData={targetChartData.postEvNtmEbitda} label='Post EV / NTM EBITDA' valueType='multiple' />
      </Flex>
    </Flex>
  )
}

export default FundingBenchmarkingDashboards
