import { Loader, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import MeetingNotes from '../components/MeetingNotes'
import { useMeetingNotes } from '../hooks/useMeetingNotes'
import { useInvestments } from '../hooks/useInvestments'
import '../styles/companyOverview.css'
import GlassCard from './GlassCard'
import InvestmentsView from './InvestmentsView'
import { useDealLog } from '../hooks/useDealLog'
import DealLogView from '../components/DealLogView'
import { useTiering } from '../hooks/useTiering'
import TieringView from '../components/TieringView'
import LoadingWaiter from '../components/LoadingWaiter'

interface CompanyOverviewProps {
  companyName: string
  companyLabel: string
  dealId?: string
}

const ExtendProfileView: FunctionComponent<CompanyOverviewProps> = ({ dealId, companyName, companyLabel }) => {
  const [investments, isILoading, iError] = useInvestments({ companyName })
  const [dealLog, isDLLoading, dlError] = useDealLog({ dealId })
  const [meetingNotes, isMLoading, mError] = useMeetingNotes({ companyName, companyLabel, enabled: !!companyName })
  const [tiering, isTLoading, tError] = useTiering({ companyName })

  if (mError?.message?.includes('not enabled') || iError?.message?.includes('not enabled')) {
    return (
      <GlassCard header='' className='crmCard lastCard'>
        <Text>No CRM information available</Text>
      </GlassCard>
    )
  }

  return (
    <GlassCard header='' className='crmCard lastCard'>
      <LoadingWaiter isLoading={isILoading} error={iError}>
        <InvestmentsView investments={investments} isEditable={false} setUpdate={() => {}} />
      </LoadingWaiter>
      <LoadingWaiter isLoading={isMLoading} error={mError}>
        <MeetingNotes meetingNotes={meetingNotes} companyName={companyName} />
      </LoadingWaiter>
      <LoadingWaiter isLoading={isDLLoading} error={dlError}>
        <DealLogView dealLog={dealLog} companyName={companyName} />
      </LoadingWaiter>
      <LoadingWaiter isLoading={isTLoading} error={tError}>
        <TieringView tiering={tiering[0]} companyName={companyName} companyLabel={companyLabel} />
      </LoadingWaiter>
    </GlassCard>
  )
}

export default ExtendProfileView
